import SnackBarAlert, { SnackBarAlertProps } from "@components/SnackBarAlert/SnackBarAlert";
import React, { createContext, useContext, useReducer } from "react";
import { ALERT_INITIAL_STATE } from "./constant";
import { AlertAction, AlertContextProps } from "./type";

/**
 * alertReducer is a reducer function for alert state management
 * It will return a new state based on the action type.
 *
 * @param {SnackBarAlertProps} state
 * @param {AlertAction} action
 * @return {*}  {SnackBarAlertProps}
 */
const alertReducer = (state: SnackBarAlertProps, action: AlertAction): SnackBarAlertProps => {
  const { type, message = "" } = action;

  switch (type) {
    case "SET_SUCCESS_ALERT":
      return {
        open: true,
        message: message,
        severity: "success"
      };
    case "SET_ERROR_ALERT":
      return {
        open: true,
        message: message,
        severity: "error"
      };
    case "SET_INFO_ALERT":
      return {
        open: true,
        message: message,
        severity: "info"
      };
    case "SET_WARNING_ALERT":
      return {
        open: true,
        message: message,
        severity: "warning"
      };
    case "CLOSE_ALERT":
      return {
        ...state,
        open: false
      };
    default:
      throw new Error("Invalid action type");
  }
};

const AlertContext = createContext<AlertContextProps>({ alert: ALERT_INITIAL_STATE, alertDispatch: () => {} });

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alert, alertDispatch] = useReducer(alertReducer, ALERT_INITIAL_STATE);

  // onClose is the required callback function for the alert component
  // to close the alert after time lapsed
  const onClose = () => {
    alertDispatch({ type: "CLOSE_ALERT" });
  };

  return (
    // Append the alert component to the end of the DOM tree
    <AlertContext.Provider value={{ alert, alertDispatch }}>
      {children}
      <SnackBarAlert {...alert} onClose={onClose} />
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);

export default AlertProvider;
export { useAlert };
