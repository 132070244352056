import { GuideCardProps } from "@components/guideCard/GuideCard";

const fullyDigitalTest: GuideCardProps = {
  id: "digital-test",
  title: "Ataraxis Breast (Digital; RUO)",
  text: "Select this to order Ataraxis Breast and upload a file with your pathology slide to receive results within minutes. This test is for Research Use Only, not for use in diagnostic procedures.",
  link: "/order/source-selection",
  imageUrl: "/images/illustration-digital-test3.png"
};

const cliaTest: GuideCardProps = {
  id: "digital-test1",
  title: "Ataraxis Breast (Laboratory Test)",
  text: "Ataraxis is in progress of establishing a CLIA-certified laboratory and expects to be able to take in physical samples soon.",
  link: "/order/lab-test",
  imageUrl: "/images/illustration-clia2.png",
  disable: true,
  buttonText: "Available soon"
};

export { fullyDigitalTest, cliaTest };
