import { db } from "@config/firebase";
import { AuthUserNotFoundException } from "@repository/auth";

import { AtaraxisUser, getOrgName, getUser } from "@repository/user";
import { useAuth } from "@store/auth/model";
import { doc, DocumentReference } from "firebase/firestore";
import { useEffect, useState } from "react";

interface UserWithOrg extends AtaraxisUser {
  organizationName: string;
}

// Convert DocumentReference into String for serialization
function jsonReplacer(key: string, value: any) {
  if (value instanceof DocumentReference) {
    return value.path;
  } else {
    return value;
  }
}

function useCurrentUserData() {
  const auth = useAuth();
  const [userData, setUserData] = useState<UserWithOrg>({} as UserWithOrg);

  useEffect(() => {
    async function fetchUserData() {
      if (!auth) throw new AuthUserNotFoundException();

      // try getting the user data from the session storage
      const cachedUserData = sessionStorage.getItem(`userData-${auth.uid}`);

      // if the data exists in session storage, use that data
      if (cachedUserData) {
        const retrievedUserData = JSON.parse(cachedUserData);
        retrievedUserData.lastLoginTime = new Date(retrievedUserData.lastLoginTime.seconds * 1000);
        retrievedUserData.organization = doc(db, retrievedUserData.organization);
        setUserData(retrievedUserData);
      } else {
        // otherwise fetch the data from the server and store it in the session storage
        const data = await getUser(auth.uid);
        if (!data) return {} as AtaraxisUser;
        const orgName = await getOrgName(data.organization);
        const result = { ...data, organizationName: orgName };
        setUserData(result);

        // cache the data in the session storage
        // note that sessionStorage.setItem() stores string data, so we must stringify our object
        sessionStorage.setItem(`userData-${auth.uid}`, JSON.stringify(result, jsonReplacer));
      }
    }

    fetchUserData();
  }, [auth]);

  return userData;
}

export { type UserWithOrg, useCurrentUserData };
