import { Button, Typography } from "@mui/material";
import { StandardTestForm } from "@utils/interface/order";
import { useFieldArray, useFormContext, UseFormReturn } from "react-hook-form";
import { CancerFocus } from "./CancerFocus";

/**
 * @fileoverview This file contains the CancerFoci component and its props type declaration.
 * */

/** CancerFoci Props Type Declaration**/
interface CancerFociProps {
  methods: UseFormReturn<StandardTestForm>;
}

/**
 * CancerFoci component.
 * Order Test Subcomponent that handles the cancer foci, allowing the user to add individual Cancer Focus to the form
 * Cancer Focus components are generated based on the number of foci fields in the form.
 * Clicking the button  allows the user to add more foci.
 * */
const CancerFoci = (props: CancerFociProps) => {
  const { getValues, setValue } = props.methods;
  const foci = getValues("patient.foci");

  const handleFociChange = (updatedFoci: any) => {
    setValue("patient.foci", updatedFoci);
  };
  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Tumor Characteristics
      </Typography>
      {foci && <CancerFocus methods={props.methods} />}
    </>
  );
};
export default CancerFoci;
