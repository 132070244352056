import { Timestamp } from "firebase/firestore";

interface IBase {
  uid: string;
  id?: string;
  createdTime: Timestamp;
}

class Base implements IBase {
  id?: string;

  uid: string;

  createdTime: Timestamp;

  constructor(uid: string, createdTime?: Timestamp, id?: string) {
    this.uid = uid;
    this.createdTime = createdTime ? createdTime : Timestamp.now();
    this.id = id ? id : undefined;
  }
}

export default Base;
