import { createTheme, Theme, ThemeProvider as Provider } from "@mui/material";
import { createContext, useContext, useReducer } from "react";
import { INIT_THEME } from "./constant";
import { ThemeAction, ThemeContext as IThemeContext } from "./type";

const themeReducer = (_: Theme, action: ThemeAction): Theme => {
  switch (action.type) {
    case "LIGHT":
      return createTheme();

    case "DARK":
      return createTheme({
        palette: {
          mode: "dark"
        }
      });

    case "ATARAXIS":
    default:
      return createTheme({
        palette: {
          primary: {
            main: "#3a34b2"
          }
        }
      });
  }
};

const ThemeContext = createContext<IThemeContext>({ theme: INIT_THEME, themeDispatch: () => {} });

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, themeDispatch] = useReducer(themeReducer, INIT_THEME);

  return (
    <ThemeContext.Provider value={{ theme, themeDispatch }}>
      <Provider theme={theme}>{children}</Provider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { ThemeProvider, useTheme };
