import { analytics, db } from "@config/firebase";
import { logEvent } from "firebase/analytics";
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  Query,
  query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  increment,
  type FirestoreDataConverter,
  type SnapshotOptions
} from "firebase/firestore";
import Base from "./base";

const table = "users";

interface IUserNotFoundException {
  name: string;
}

class UserNotFoundException extends Error implements IUserNotFoundException {
  name = "UserNotFoundException";
}

interface ShowGuide {
  order: boolean;
  history: boolean;
}

interface OrgSettings {
  allowViewOtherMemberOrders: boolean;
}

/**
 * @interface IUser
 * @field firstname: first name of user
 * @field lastname: last name of user
 * @field email: email of user
 * @field organization: document reference to organization in organization table
 * @field role: role of user
 * @field show_guide: if user should see guide
 */
interface IUser {
  firstname: string;
  lastname: string;
  email: string;
  organization: DocumentReference;
  role: string;
  orderedTestsNumber: number;
  showGuide: ShowGuide;
  lastLoginTime: Timestamp;
  orgSettings: OrgSettings;
}

/**
 *
 *
 * @class AtaraxisUser
 * @extends {Base}
 * @implements {IUser}
 */

class AtaraxisUser extends Base implements IUser {
  firstname: string;

  lastname: string;

  email: string;

  organization: DocumentReference;

  role: string;

  orderedTestsNumber: number;

  showGuide: ShowGuide;

  lastLoginTime: Timestamp;

  orgSettings: OrgSettings;

  constructor(
    uid: string,
    firstname: string,
    lastname: string,
    email: string,
    organization: DocumentReference,
    role: string,
    orderedTestsNumber: number,
    showGuide: ShowGuide = {
      order: true,
      history: true
    },
    orgSettings: OrgSettings = {
      allowViewOtherMemberOrders: true
    },
    createdTime?: Timestamp,
    lastLoginTime?: Timestamp,
    id?: string
  ) {
    super(uid, createdTime, id);
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.organization = organization;
    this.role = role;
    this.showGuide = showGuide;
    this.orgSettings = orgSettings;
    this.orderedTestsNumber = orderedTestsNumber;
    this.lastLoginTime = lastLoginTime ? lastLoginTime : Timestamp.now();
  }
}

const userConverter: FirestoreDataConverter<AtaraxisUser> = {
  toFirestore: (user: AtaraxisUser) => {
    return {
      created_time: user.createdTime,
      last_login_time: user.lastLoginTime,
      uid: user.uid,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      organization: user.organization,
      role: user.role,
      orderedTestsNumber: user.orderedTestsNumber,
      show_guide: user.showGuide,
      org_settings: user.orgSettings
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<AtaraxisUser>, options?: SnapshotOptions) => {
    const data: any = snapshot.data(options);
    return new AtaraxisUser(
      data.uid,
      data.firstname,
      data.lastname,
      data.email,
      data.organization,
      data.role,
      data.orderedTestsNumber,
      data.show_guide,
      data.org_settings,
      data.created_time,
      data.last_login_time,
      snapshot.id
    );
  }
};

/**
 * Add user to user table
 *
 * @param {User} user
 * @return {*}  {(Promise<string | undefined>)}
 */
const addUser = async (user: AtaraxisUser): Promise<string | undefined> => {
  logEvent(analytics, "add_user");
  try {
    await setDoc(doc(db, table, user.uid).withConverter(userConverter), user);
    console.log("Document written with ID: ", user.uid);
    return user.uid;
  } catch (error) {
    alert(error);
    console.log(error);
  }
};

/**
 * Get user by id
 *
 * @param {string} userId auth id of user
 */
const getUser = async (userId: string): Promise<AtaraxisUser | null> => {
  logEvent(analytics, "get_user");
  const userDocRef = doc(db, table, userId).withConverter(userConverter);
  const userSnapshot = await getDoc(userDocRef);
  if (userSnapshot.exists()) return userSnapshot.data();
  else return null;
};

/**
 * Get user by Organization
 *
 * @param {DocumentReference} orgRef organization of user @TODO SHOULD BE CHANGED TO JUST ORG NAME
 */
const getUsersByOrganization = async (orgRef: DocumentReference): Promise<AtaraxisUser[]> => {
  const q: Query<AtaraxisUser> = query(
    collection(db, table),
    where("organization", "==", orgRef)
  ) as Query<AtaraxisUser>;
  const userSnapshot = await getDocs<AtaraxisUser>(q);
  logEvent(analytics, "get_users_by_organization", { usersCount: userSnapshot.size });
  return userSnapshot.docs.map((d) => {
    return userConverter.fromFirestore(d);
  });
};

/**
 * Get the ordered tests number in entire organization
 *
 * @param {DocumentReference} orgRef organization of user @TODO SHOULD BE CHANGED TO JUST ORG NAME
 */
const getOrderedTestsNumberByOrganization = async (orgRef: DocumentReference): Promise<number> => {
  const q: Query<AtaraxisUser> = query(
    collection(db, table),
    where("organization", "==", orgRef)
  ) as Query<AtaraxisUser>;
  const userSnapshot = await getDocs<AtaraxisUser>(q);
  logEvent(analytics, "get_users_by_organization", { usersCount: userSnapshot.size });
  const users = userSnapshot.docs.map((d) => userConverter.fromFirestore(d));
  let totalTestsNumber = 0;
  for (const user of users) {
    totalTestsNumber += user.orderedTestsNumber ?? 0;
  }
  return totalTestsNumber;
};

/**
 * Get Organization Name
 *
 * @param {DocumentReference} orgRef organization reference of user
 */
const getOrgName = async (orgRef: DocumentReference): Promise<string> => {
  logEvent(analytics, "get_org_name");
  const d = await getDoc(orgRef);
  return d.data()?.name ?? "Unknown Organization";
};

/**
 * Get Ordered Tests Number
 *
 * @param {userId} userId auth id of user
 */
const getOrderedTestsNumber = async (userId: string): Promise<number | null> => {
  logEvent(analytics, "get_ordered_tests_number");
  const userDocref = doc(db, table, userId).withConverter(userConverter);
  const userSnapshot = await getDoc(userDocref);
  if (userSnapshot.exists()) return userSnapshot.data()?.orderedTestsNumber;
  else return null;
};

/**
 * Get Rile of a The Passed-in User
 *
 * @param {userId} userId auth id of user
 */
const getOrganization = async (userId: string): Promise<DocumentReference | null> => {
  logEvent(analytics, "get_organization");
  const userDocref = doc(db, table, userId).withConverter(userConverter);
  const userSnapshot = await getDoc(userDocref);
  if (userSnapshot.exists()) return userSnapshot.data()?.organization;
  else return null;
};

/**
 * Update show guide status for order in user
 *
 * @param {string} userId user id
 * @param {boolean} value value to update to
 */
const updateUserShowGuideStatusOrder = async (userId: string, value: boolean) => {
  logEvent(analytics, "update_user_show_guide_status_order");
  const collectionReference = collection(db, table);
  const docQuery = where("uid", "==", userId);
  await getDocs(query(collectionReference, docQuery)).then((querySnapshot) => {
    querySnapshot.forEach((userDoc) => {
      updateDoc(userDoc.ref, {
        show_guide: {
          order: value,
          history: userDoc.data().show_guide.history
        }
      });
    });
  });
};

/**
 * Update org settings in user
 *
 * @param {string} userId user id
 * @param {boolean}  allowViewOtherMemberOrders for allowing users to view other orders in same org
 */
const updateUserOrgSettings = async (userId: string, allowViewOtherMemberOrders: boolean) => {
  logEvent(analytics, "update_user_org_settings");
  const collectionReference = collection(db, table);
  const docQuery = where("uid", "==", userId);
  await getDocs(query(collectionReference, docQuery)).then((querySnapshot) => {
    querySnapshot.forEach((userDoc) => {
      updateDoc(userDoc.ref, {
        org_settings: {
          allowViewOtherMemberOrders: allowViewOtherMemberOrders
        }
      });
    });
  });
};

/**
 * Update role of user
 *
 * @param {string} userId user id
 * @param {string} role value to update to
 */
const updateUserRole = async (userId: string, role: string) => {
  logEvent(analytics, "update_user_role");
  const collectionReference = collection(db, table);
  const docQuery = where("uid", "==", userId);
  await getDocs(query(collectionReference, docQuery)).then((querySnapshot) => {
    querySnapshot.forEach((userDoc) => {
      updateDoc(userDoc.ref, {
        role
      });
    });
  });
};

/**
 * Increase the ordered test number of user by 1
 *
 * @param {string} userId user id
 */
const incrementOrderedTestsNumber = async (userId: string) => {
  logEvent(analytics, "increase_user_ordered_tests_number");
  const collectionReference = collection(db, table);
  const docQuery = where("uid", "==", userId);
  await getDocs(query(collectionReference, docQuery)).then((querySnapshot) => {
    querySnapshot.forEach((userDoc) => {
      updateDoc(userDoc.ref, {
        orderedTestsNumber: increment(1)
      });
    });
  });
};

export {
  addUser,
  getUser,
  updateUserShowGuideStatusOrder,
  getOrgName,
  getUsersByOrganization,
  getOrderedTestsNumberByOrganization,
  getOrderedTestsNumber,
  getOrganization,
  AtaraxisUser,
  UserNotFoundException,
  updateUserRole,
  updateUserOrgSettings,
  incrementOrderedTestsNumber,
  type IUser
};
