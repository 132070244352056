import { StandardTestForm } from "@utils/interface/order";
import { type User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { type UploadTaskSnapshot } from "firebase/storage";
import { Dispatch, SetStateAction } from "react";
import { stateChangeHandler, storageErrorHandler, uploadFileToStorage } from ".";
import { useState, useEffect } from "react";

const root = "patient";
const infRoot = "inference";

enum FileType {
  //MRI = "mri",
  Histology = "histology"
}

/**
 * State change handler for monitor the progress of current processes
 *
 * @param {FileList} files
 * @param {number} index
 * @param {Dispatch<SetStateAction<number[]>>} setProgresses
 * @return {*}  {((arg0: UploadTaskSnapshot) => void)}
 */
const dicomStateChangeHandler = (
  files: FileList,
  index: number,
  setProgresses: Dispatch<SetStateAction<number[]>>
): ((arg0: UploadTaskSnapshot) => void) => {
  const callback = (snapshot: UploadTaskSnapshot) => {
    const progress = snapshot.bytesTransferred / snapshot.totalBytes / files.length;
    setProgresses((prevProgresses) => {
      const newProgresses = [...prevProgresses];
      newProgresses[index] = progress;
      return newProgresses;
    });
  };

  return (snapshot: UploadTaskSnapshot) => {
    return stateChangeHandler(snapshot, callback);
  };
};

/**
 * Upload fileList to storage
 * Path is determined by file type prefixed with filePath
 * Following with subscriber to subscribe state change info
 *
 * @param {string} filePath
 * @param {FileType} fileType
 * @param {FileList} files
 * @param {Dispatch<SetStateAction<number[]>>} setProgresses
 * @return {*}
 */
const uploadDicomsToStorage = async (
  filePath: string,
  fileType: FileType,
  files: FileList,
  setProgresses: Dispatch<SetStateAction<number[]>>
) => {
  const promises = [];
  for (let i = 0; i < files.length; i++) {
    const uploadTask = uploadFileToStorage(files[i], `${root}/${fileType}/${filePath}/${files[i].name}`);
    promises.push(uploadTask);
    uploadTask.on("state_changed", dicomStateChangeHandler(files, i, setProgresses), storageErrorHandler);
  }
  return Promise.all(promises);
};

const uploadDicomsToStorageInf = async (
  pathologyFilePath: string,
  filePath: string,
  files: FileList,
  setProgresses: Dispatch<SetStateAction<number[]>>
) => {
  const promises = [];
  const infPatients = "patients";
  for (let i = 0; i < files.length; i++) {
    const uploadTask = uploadFileToStorage(files[i], `${infRoot}/${infPatients}/${filePath}/${pathologyFilePath}.svs`);
    promises.push(uploadTask);
    uploadTask.on("state_changed", dicomStateChangeHandler(files, i, setProgresses), storageErrorHandler);
  }
  return Promise.all(promises);
};

const createNewDirectToStorageInf = async (
  filePath: string,
  file: File,
  setProgresses: Dispatch<SetStateAction<number[]>>
) => {
  const promises = [];
  const infPatients = "patients";
  const uploadTask = uploadFileToStorage(file, `${infRoot}/${infPatients}/${filePath}/${file.name}`);
  promises.push(uploadTask);
  return Promise.all(promises);
};

/**
 * Upload both optional MRI and Histology files to storage with path string
 * string with `patient`, independent file type, userId and folderId
 * //NOTE - FolderId is constructed using current timestamp
 *
 * @param {User} authUser
 * @param {StandardTestForm} orderFormData
 * @param {Dispatch<SetStateAction<number[]>>} setProgresses
 * @return {*}  {Promise<string>} Return folderId
 */
const uploadHistologyToStorage = (
  authUser: User,
  orderFormData: StandardTestForm,
  setProgresses: Dispatch<SetStateAction<number[]>>
): Promise<string> => {
  // Use timestamp as folder name
  const folderId = Timestamp.now().toMillis().toString();
  const filePath = `${authUser.uid}/${folderId}`;
  const promises = [];
  //if (orderFormData.orderFilesMri)
  //  promises.push(uploadDicomsToStorage(filePath, FileType.MRI, orderFormData.orderFilesMri, setProgresses));
  if (orderFormData.orderFilesHistology)
    promises.push(
      uploadDicomsToStorage(filePath, FileType.Histology, orderFormData.orderFilesHistology, setProgresses)
    );
  return Promise.all(promises).then(() => folderId);
};

const uploadHistologyToStorageInf = (
  authUser: User,
  orderFormData: StandardTestForm,
  setProgresses: Dispatch<SetStateAction<number[]>>,
  folderPatientId: string,
  firstName: string,
  lastName: string
): Promise<string | undefined> => {
  const currentTime = new Date();
  const year = currentTime.getFullYear();
  const month = String(currentTime.getMonth() + 1).padStart(2, "0");
  const day = String(currentTime.getDate()).padStart(2, "0");
  const hours = String(currentTime.getHours()).padStart(2, "0");
  const minutes = String(currentTime.getMinutes()).padStart(2, "0");
  const seconds = String(currentTime.getSeconds()).padStart(2, "0");
  const filePath = `${firstName}-${lastName}-${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${folderPatientId}`;
  const pathologyFilePath = `${folderPatientId}`;
  const promises = [];
  //if (orderFormData.orderFilesMri)
  //  promises.push(uploadDicomsToStorage(filePath, FileType.MRI, orderFormData.orderFilesMri, setProgresses));
  if (orderFormData.orderFilesHistology) {
    promises.push(
      uploadDicomsToStorageInf(pathologyFilePath, filePath, orderFormData.orderFilesHistology, setProgresses)
    );
  } else {
    const placeHolderBlob = new Blob([""], { type: "text/plain" });
    const placeHolderFile = new File([placeHolderBlob], "placeholder.txt", { type: "text/plain" });
    promises.push(createNewDirectToStorageInf(filePath, placeHolderFile, setProgresses));
  }
  return Promise.all(promises).then(
    () => filePath
    //(orderFormData && orderFormData.orderFilesHistology && orderFormData.orderFilesHistology[0])?.name
  );
};

export { FileType, uploadHistologyToStorage, uploadHistologyToStorageInf };
