import { storage } from "@config/firebase";
import {
  ref,
  StorageError,
  StorageErrorCode,
  uploadBytesResumable,
  type UploadMetadata,
  type UploadTask,
  type UploadTaskSnapshot
} from "firebase/storage";

/**
 * Firebase storage upload unit
 *
 * @param {File} file
 * @param {string} filePath
 * @param {UploadMetadata} [metadata]
 * @return {*}  {UploadTask}
 */
const uploadFileToStorage = (file: File, filePath: string, metadata?: UploadMetadata): UploadTask => {
  const fileRef = ref(storage, filePath);
  return uploadBytesResumable(fileRef, file, metadata);
};

/**
 * Firebase storage state change handler unit
 *
 * @param {UploadTaskSnapshot} snapshot
 * @param {(snapshot: UploadTaskSnapshot) => void} [callback]
 */
const stateChangeHandler = (snapshot: UploadTaskSnapshot, callback?: (snapshot: UploadTaskSnapshot) => void) => {
  if (callback) callback(snapshot);
  switch (snapshot.state) {
    case "running":
      console.log("Upload is running");
      break;
    case "paused":
      console.log("Upload is paused");
      break;
    case "success":
      console.log("Upload is success");
      break;
    case "canceled":
      console.log("Upload is canceled");
      break;
    case "error":
      console.log("Upload is error");
      break;
  }
};

/**
 * Storage error handler
 * TODO - Handle storage error code
 * @param {StorageError} error
 */
const storageErrorHandler = (error: StorageError) => {
  switch (error.code) {
    case `storage/${StorageErrorCode.UNKNOWN}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.OBJECT_NOT_FOUND}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.BUCKET_NOT_FOUND}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.PROJECT_NOT_FOUND}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.QUOTA_EXCEEDED}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.UNAUTHENTICATED}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.UNAUTHORIZED}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.UNAUTHORIZED_APP}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.RETRY_LIMIT_EXCEEDED}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_CHECKSUM}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.CANCELED}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_EVENT_NAME}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_URL}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_DEFAULT_BUCKET}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.NO_DEFAULT_BUCKET}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.CANNOT_SLICE_BLOB}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.SERVER_FILE_WRONG_SIZE}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.NO_DOWNLOAD_URL}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_ARGUMENT}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_ARGUMENT_COUNT}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.APP_DELETED}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_ROOT_OPERATION}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INVALID_FORMAT}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.INTERNAL_ERROR}`:
      alert(error.message);
      break;
    case `storage/${StorageErrorCode.UNSUPPORTED_ENVIRONMENT}`:
      alert(error.message);
      break;
  }
};

export { uploadFileToStorage, storageErrorHandler, stateChangeHandler };
