import { analytics, db } from "@config/firebase";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  orderBy,
  query,
  Query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";
import Base from "./base";

const table = "patients";

/**
 * @interface IPatient
 * @field demography: patient demography information
 * @field staging: patient cancer staging
 * @field foci: patient cancer foci
 */
interface IPatient {
  demography: IDemography;
  staging: ICancerStaging;
  foci: ICancerFoci;
}

interface IDemography {
  name: string;
  dob: string;
  menopausal_status: string;
}

interface ICancerStaging {
  tnmCategoryT: string | null;
  tnmCategoryN: string | null;
}

interface ICancerFoci {
  focusSize: number | null;
  positiveNodesNum: number | null;
  histology: IHistology;
  estrogen: string;
  progesterone: string;
  her2: string;
  ki67: number;
}

interface IHistology {
  subtypes: Array<string>;
  grade: string;
}

/**
 *
 *
 * @class Patient
 * @extends {Base}
 * @implements {IPatient}
 */

class Patient extends Base implements IPatient {
  demography: IDemography;

  staging: ICancerStaging;

  foci: ICancerFoci;

  orderIds: string[];

  patientId: string;

  constructor(
    patientId: string,
    uid: string,
    demography: IDemography,
    staging: ICancerStaging,
    foci: ICancerFoci,
    orderIds: string[],
    createdTime?: Timestamp,
    id?: string
  ) {
    super(uid, createdTime, id);
    this.demography = demography;
    this.staging = staging;
    this.foci = foci;
    this.orderIds = orderIds;
    this.patientId = patientId;
  }
}

/** @type {*} */
const patientConverter: FirestoreDataConverter<Patient> = {
  toFirestore: (patient: Patient) => {
    return {
      created_time: patient.createdTime,
      uid: patient.uid,
      demography: patient.demography,
      staging: { tnm_category_n: patient.staging.tnmCategoryN, tnm_category_t: patient.staging.tnmCategoryT },
      foci: patient.foci,
      order_ids: patient.orderIds,
      patient_id: patient.patientId
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<Patient>, options?: SnapshotOptions) => {
    const data: any = snapshot.data(options);
    return new Patient(
      data.patient_id,
      data.uid,
      data.demography,
      data.staging,
      data.foci,
      data.order_ids,
      data.created_time,
      snapshot.id
    );
  }
};

class PatientRepository {
  /* -------------------------------------------------------------------------- */
  /*                                   Create                                   */
  /* -------------------------------------------------------------------------- */

  /**
   * Add patient to firestore
   *
   * @static
   * @param {Patient} patient
   * @memberof PatientDB
   */
  static addPatientEntry = async (patient: Patient): Promise<string> => {
    logEvent(analytics, "add_patient_entry");
    const docRef = await addDoc(collection(db, table).withConverter(patientConverter), patient);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  };

  /* -------------------------------------------------------------------------- */
  /*                                     Get                                    */
  /* -------------------------------------------------------------------------- */

  /**
   * Get patient by id
   *
   * @static
   * @param {Patient} patient
   * @memberof PatientDB
   */
  static getPatientById = async (patientId: string): Promise<Patient | undefined> => {
    logEvent(analytics, "get_patient_by_id");
    const ref = doc(db, table, patientId).withConverter(patientConverter);
    const docSnap = await getDoc(ref);
    return docSnap.data();
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Update                                   */
  /* -------------------------------------------------------------------------- */

  /**
   * Update patient orders field
   *
   * @static
   * @param {string} patientId
   * @param {string} orderId
   * @memberof PatientDB
   */
  static updatePatientOrdersField = async (patientId: string, orderId: string) => {
    logEvent(analytics, "update_patient_orders_field");
    const ref = doc(db, table, patientId);
    await updateDoc(ref, {
      order_ids: arrayUnion(orderId)
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                    List                                    */
  /* -------------------------------------------------------------------------- */
  /**
   * List all patients attached to this user
   *
   * @static
   * @param {string} userUid
   * @memberof PatientDB
   */
  static getPatientsByIds = async (userUid: string[]): Promise<Patient[]> => {
    const q: Query<Patient> = query(
      collection(db, table),
      where("uid", "in", userUid),
      orderBy("created_time", "desc")
    ) as Query<Patient>;
    const patientSnapshot = await getDocs<Patient>(q);
    const patients = patientSnapshot.docs.map((d) => {
      return patientConverter.fromFirestore(d);
    });
    logEvent(analytics, "get_patients_by_ids", { patientsCount: patients.length });
    return patients;
  };
}
export { Patient, type IPatient, type ICancerFoci, PatientRepository };
