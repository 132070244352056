import { createContext, useContext, useReducer } from "react";
import { GUIDE_INITIAL_STATE } from "./constant";
import { GuideAction, GuideContextProps, IGuide } from "./type";

const guideReducer = (state: IGuide, action: GuideAction): IGuide => {
  const { run, stepIndex } = action;
  return { run, stepIndex };
};

/**
 * Create context with dummy data
 *
 * @type {*}
 * */
const GuideContext = createContext<GuideContextProps>({
  guide: GUIDE_INITIAL_STATE,
  guideDispatch: () => {}
});

/**
 * Provider to initialize guide context
 *
 * @param {GuideProviderProps} props
 * @return {*}  {React.ReactElement}
 */
const GuideProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [guide, guideDispatch] = useReducer(guideReducer, GUIDE_INITIAL_STATE);
  return <GuideContext.Provider value={{ guide, guideDispatch }}>{children}</GuideContext.Provider>;
};

/**
 * Getter for guide context
 *
 * @return {*}
 */
const useGuide = () => useContext(GuideContext);

export default GuideProvider;
export { useGuide };
