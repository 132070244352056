import { TextField, Typography } from "@mui/material";
import { Controller, FieldValues, useFormContext } from "react-hook-form";

/**
 * @fileoverview This file contains the FormInputField component and its prop type declarations
 * */
interface FormInputFieldProps {
  name: string;
  label: string;
  required?: boolean;
  type: string;
  shrink?: boolean;
  readOnly?: boolean;
  validate?: (value: any, formValues: FieldValues) => boolean | string;
}

/**
 * This is a reusable component for form text fields in the form
 * It also performs a check to ensure that the field is not empty.
 * */
const FormInputField = (props: FormInputFieldProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { name, label, required, type, shrink = false, readOnly = false, validate } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        validate
      }}
      render={({ field }) => (
        <>
          <TextField
            margin="normal"
            fullWidth
            type={type}
            {...field}
            required={required}
            label={label}
            InputLabelProps={{ shrink: shrink || field.value ? true : false }}
            inputProps={{ readOnly }}
            value={field.value || ""}
          />
          {errors[name] && (
            <Typography variant="caption" display="block" fontSize="small" sx={{ color: "red" }}>
              {errors[name]?.message?.toString()}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export { type FormInputFieldProps };
export default FormInputField;
