import Error404 from "@pages/404/Error404";
import App from "@pages/App/App";
import * as Sentry from "@sentry/react";

Sentry.init({
  // Common Options
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: process.env.NODE_ENV === "development",
  enabled: process.env.NODE_ENV === "production",
  attachStacktrace: true,

  // Integration Configuration
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default Sentry.withProfiler(Sentry.withErrorBoundary(App, { fallback: Error404, showDialog: true }));
