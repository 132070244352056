import openai from "@config/openai";
import { Autocomplete, Box, Button, Card, Grid, InputAdornment, Popover, Stack, TextField } from "@mui/material";
import { ICancerFoci } from "@repository/patient";
import { StandardTestForm } from "@utils/interface/order";
import { useState } from "react";
import { Controller, useForm, useFormContext, UseFormReturn, type UseFieldArrayRemove } from "react-hook-form";
import "./CancerFocus.scss";

/**
 * @fileoverview This file contains the CancerFocus component and its props type declaration.
 */

/** Options for Histological Subtype**/
const HistologicalSubtypeOptions = [
  { value: "Invasive ductal carcinoma", label: "Invasive ductal carcinoma" },
  { value: "Invasive lobular carcinoma", label: "Invasive lobular carcinoma" },
  { value: "Paget’s disease", label: "Paget’s disease" },
  { value: "Other invasive carcinoma", label: "Other invasive carcinoma" },
  { value: "Ductal carcinoma in situ", label: "Ductal carcinoma in situ" },
  { value: "Lobular carcinoma in situ", label: "Lobular carcinoma in situ" }
];

/** Options for Histological Grades**/
const HistologicalGradeOptions = [
  { value: "High Grade", label: "High Grade" },
  { value: "Intermediate Grade", label: "Intermediate Grade" },
  { value: "Low Grade", label: "Low Grade" }
];

/** Options for Estrogen Receptors**/
const estrogenReceptorOptions = [
  { value: "er-positive", label: "ER+" },
  { value: "er-negative", label: "ER-" }
];

/** Options for Progesterone Receptors**/
const progesteroneReceptorOptions = [
  { value: "pr-positive", label: "PR+" },
  { value: "pr-negative", label: "PR-" },
  { value: "pr-unknown", label: "Unknown" }
];

/** Options for her2Receptors**/
const her2ReceptorOptions = [
  { value: "her2-positive", label: "HER2+" },
  { value: "her2-negative", label: "HER2-" },
  { value: "her2-unknown", label: "Unknown" }
];

/** Props Type Decleration for Cancer Focus**/
interface CancerFocusProps {
  methods: UseFormReturn<StandardTestForm, any>;
}

interface IPathologyReport {
  text: string;
}

/**
 * CancerFocus component.
 * Handles form logic for individual Cancer Focus.
 * Has button to remove the Cancer Focus from the form.
 * Includes Feature allowing user to fill in information of focus based on pathology report using ChatGPT.
 * */
const CancerFocus = (props: CancerFocusProps) => {
  const { control } = useFormContext<StandardTestForm>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [pulse, setPulse] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(true);

  /** Handles opening of Pathology Report Loader Menu*/
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { control: localControl, handleSubmit } = useForm<IPathologyReport>();

  const handleClose = () => {
    setAnchorEl(null);
    setPulse(false);
  };

  const { setValue } = props.methods;

  const openAiCreateCompletion = async (question: string, report: string): Promise<string> => {
    const prefix = "Here is the pathology report:\n";
    const mid = "\nBased on the pathology report, please answer this question:";
    const content = prefix + report + mid + question;
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: content
    });
    console.log(response);
    return response.data.choices[0].text ? response.data.choices[0].text.trim() : "";
  };

  /** Handles ChatGPT pathology report processing */
  const onSubmit = async (data: { text: string }) => {
    setPulse(true);
    setRequired(true);
    const tumorSizeQuestion =
      "What is this patient's tumor size in this focus in mm? (Answer with range greater than 0)";
    const histologicalGradeQuestion =
      "What is this patient's histological grade? (Answer with Low Grade, Intermediate Grade, or High Grade without period)";
    const estrogenQuestion =
      "\nIs this patient estrogen receptor positive, negative, or unknown/equivocal? (Answer with er-positive, er-negative or er-unknown in all lowercase)";
    const progesteroneQuestion =
      "\nIs this patient progesterone receptor positive, negative, or unknown/equivocal? (Answer with pr-positive, pr-negative or pr-unknown in all lowercase)";
    const her2Question =
      "Is this patient HER2+ receptor positive, negative, or unknown/equivocal? (Answer with her2-positive, her2-negative or her2-unknown in all lowercase)";
    const ki67Question = "Is this patient ki-67 proliferation percentage? (Answer with range from 0 to 100)";

    const promises = [
      openAiCreateCompletion(tumorSizeQuestion, data.text),
      openAiCreateCompletion(histologicalGradeQuestion, data.text),
      openAiCreateCompletion(estrogenQuestion, data.text),
      openAiCreateCompletion(progesteroneQuestion, data.text),
      openAiCreateCompletion(her2Question, data.text),
      openAiCreateCompletion(ki67Question, data.text)
    ];

    const [focusSize, positiveNodesNum, grade, estrogen, progesterone, her2, ki67] = await Promise.all(promises);

    const foci: ICancerFoci = {
      focusSize: focusSize !== "" ? parseInt(focusSize) : null,
      positiveNodesNum: positiveNodesNum !== "" ? parseInt(positiveNodesNum) : null,
      estrogen: estrogen,
      progesterone: progesterone,
      her2: her2,
      ki67: ki67 !== "" ? parseInt(ki67) : 0,
      histology: {
        grade: grade !== "" ? grade : "0",
        subtypes: []
      }
    };
    setValue("patient.foci", foci);
    setPulse(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Card
      className={pulse ? "pulse" : ""}
      sx={{ px: 1, py: 2, my: 1, boxShadow: 0, border: 1, borderRadius: 2, borderColor: "grey.200" }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            PaperProps={{
              style: { width: 500 }
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="text"
                control={localControl}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Paste your pathology report here"
                    multiline
                    rows={20}
                    sx={{ width: "100%" }}
                  />
                )}
              />
              <Stack direction="row">
                <Button type="submit" variant="contained" sx={{ width: "100%" }} onClick={handleClose}>
                  Submit
                </Button>
              </Stack>
            </form>
          </Popover>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name={`patient.foci.histology.subtypes`}
            control={control}
            rules={{ required: "At least one subtype must be selected" }}
            render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
              <Autocomplete
                multiple
                sx={{ m: 1 }}
                ref={ref}
                options={HistologicalSubtypeOptions}
                value={value.map((val) => HistologicalSubtypeOptions.find((option) => option.value === val)) || []}
                onChange={(event, option) => onChange(option.map((o) => (o as { value: string }).value))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={name}
                    label="Histological subtypes"
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            )}
          />
          <Controller
            name={`patient.foci.histology.grade`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Autocomplete
                sx={{ m: 1 }}
                onChange={(event, option) => onChange(option?.value)}
                value={HistologicalGradeOptions.find((option) => option.value === value) || null}
                options={HistologicalGradeOptions}
                renderInput={(params) => (
                  <TextField {...params} name={name} label="Histological grade of invasive tumor" />
                )}
              />
            )}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              alignItems: "center",
              gap: 2
            }}
          >
            <Controller
              name={`patient.foci.estrogen`}
              control={control}
              rules={{ required }}
              render={({ field: { onChange, value, name } }) => (
                <Autocomplete
                  sx={{ m: 1 }}
                  fullWidth
                  onChange={(event, option) => onChange(option?.value)}
                  value={estrogenReceptorOptions.find((option) => option.value === value) || null}
                  options={estrogenReceptorOptions}
                  renderInput={(params) => (
                    <TextField {...params} name={name} label="Estrogen receptor" required={required} />
                  )}
                />
              )}
            />
            <Controller
              name={`patient.foci.progesterone`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Autocomplete
                  sx={{ m: 1 }}
                  fullWidth
                  onChange={(event, option) => onChange(option?.value)}
                  value={progesteroneReceptorOptions.find((option) => option.value === value) || null}
                  options={progesteroneReceptorOptions}
                  renderInput={(params) => <TextField {...params} name={name} label="Progesterone receptor" />}
                />
              )}
            />
            <Controller
              name={`patient.foci.her2`}
              control={control}
              rules={{ required }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <Autocomplete
                  sx={{ m: 1 }}
                  fullWidth
                  onChange={(event, option) => onChange(option?.value)}
                  value={her2ReceptorOptions.find((option) => option.value === value) || null}
                  options={her2ReceptorOptions}
                  renderInput={(params) => (
                    <TextField {...params} name={name} label="HER-2 receptor" required={required} />
                  )}
                />
              )}
            />
            <Controller
              name={`patient.foci.ki67`}
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <TextField
                  {...rest}
                  fullWidth
                  type="number"
                  label="Ki-67 proliferation"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 100
                    },
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  sx={{ m: 1 }}
                  onChange={(e) => onChange(parseInt(e.target.value))}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export { CancerFocus };
