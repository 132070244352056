import useGuideRepository from "@repository/guide";
import { useGuide } from "@store/guide/model";
import ReactJoyride from "react-joyride";

const steps = [
  {
    target: "#digital-test",
    content: "Order a fully digital test as the first step",
    disableBeacon: true
  },
  {
    target: "#digital-test1",
    content: "Order a test manually",
    disableBeacon: true
  },
  {
    target: "#manual-test-data",
    content: "Order a test manually",
    disableBeacon: true
  },
  {
    target: "#fill-test-form",
    content: "Fill out the form",
    disableBeacon: true
  },
  {
    target: "#test-sample-data",
    content: "Or you can fill in sample data",
    disableBeacon: true
  },
  {
    target: "#test-order-submit",
    content: "Submit the form once finish",
    disableBeacon: true
  }
];

const WithGuide = (Component: React.FC) => {
  const WithGuideComponent = ({ ...props }) => {
    const { guide } = useGuide();
    const { handleCallback } = useGuideRepository();
    return (
      <>
        <ReactJoyride
          continuous
          hideCloseButton
          showProgress
          run={guide.run}
          stepIndex={guide.stepIndex}
          steps={steps}
          callback={handleCallback}
        />
        <Component {...props} />
      </>
    );
  };
  return WithGuideComponent;
};

export default WithGuide;
