import { useGuide } from "@/store/guide/model";

import { getUser } from "@repository/user";
import { useAuth } from "@store/auth/model";
import { User } from "firebase/auth";
import { useCallback, useEffect } from "react";

const useOrderTest = () => {
  const auth = useAuth();
  const { guideDispatch } = useGuide();

  const fetchUser = useCallback(
    async (_authUser: User) => {
      const user = await getUser(_authUser.uid);
      if (user && user.showGuide && user.showGuide.order) {
        guideDispatch({ run: true, stepIndex: 0 });
      }
    },
    [guideDispatch]
  );

  useEffect(() => {
    if (!auth) return;
    fetchUser(auth);
  }, [fetchUser, auth]);
};

export default useOrderTest;
