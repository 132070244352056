import { Grid } from "@mui/material";

const WithOrderFormLayOut = (WrappedComponent: any) => {
  const WithOrderFormLayOutComponent = ({ ...props }) => {
    return (
      <Grid
        container
        wrap="nowrap"
        sx={{
          pt: 4,
          px: { md: 10, sm: 3, xs: 3 },
          display: "flex",
          flexDirection: { md: "row", sm: "column-reverse", xs: "column-reverse" },
          alignItems: { md: "start", sm: "center", xs: "center" },
          gap: { md: 5, sm: 0, xs: 0 }
        }}
      >
        <WrappedComponent {...props} />
      </Grid>
    );
  };

  return WithOrderFormLayOutComponent;
};

export default WithOrderFormLayOut;
