import GuideCard from "@components/guideCard/GuideCard";
import WithTestSelectionLayOut from "@hoc/withOrderSelectionLayOut";
import { cliaTest, fullyDigitalTest } from "./constants";

const TypeSelection = () => {
  return (
    <>
      <GuideCard {...fullyDigitalTest} />
      <GuideCard {...cliaTest} />
    </>
  );
};

export default WithTestSelectionLayOut(TypeSelection);
