import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = ({
  isPermitted,
  redirectPath = "/login",
  children
}: {
  isPermitted: boolean;
  redirectPath?: string;
  children?: React.ReactElement;
}) => {
  const location = useLocation();

  if (isPermitted) {
    return children ? children : <Outlet />;
  }
  // Memorize user's prev location
  return <Navigate to={redirectPath} replace state={{ from: location }} />;
};

export default ProtectedRoute;
