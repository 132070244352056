import apiResource from "@config/axios";

const useSession = () => {
  const sessionLogin = async (idToken: string) => {
    try {
      await apiResource.post("auth/session-login", { idToken }, { withCredentials: true });
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  const sessionLogout = () => {
    try {
      apiResource.post("auth/session-logout", {}, { withCredentials: true });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  return { sessionLogin, sessionLogout };
};

export default useSession;
