import { auth } from "@config/firebase";
import useSession from "@hooks/session";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useCallback } from "react";

interface IAuthNotFoundException {
  name: string;
}

class AuthUserNotFoundException extends Error implements IAuthNotFoundException {
  name = "AuthUserNotFoundException";
}

type AuthFormSubmitSuccessCallback = ({ ...rest }) => Promise<void>;
type AuthFormSubmitErrorCallback = (error: any) => void;

interface AuthLogInFormProps {
  email: string;
  password: string;
}

interface AuthResetPasswordFormProps {
  email: string;
}

interface AuthSignUpFormProps {
  email: string;
  password: string;
  repeatPassword: string;
  firstName: string;
  lastName: string;
  role: string;
}

const AuthRepository = () => {
  const { sessionLogout } = useSession();

  const authLogin =
    (onSuccess: AuthFormSubmitSuccessCallback, onError: AuthFormSubmitErrorCallback) =>
    async (data: AuthLogInFormProps) => {
      const { email, password } = data as AuthLogInFormProps;
      try {
        const resp = await signInWithEmailAndPassword(auth, email, password);
        onSuccess({ ...data, ...resp });
      } catch (error) {
        onError(error);
      }
    };
  const authSignUp =
    (onSuccess: AuthFormSubmitSuccessCallback, onError: AuthFormSubmitErrorCallback) =>
    async (data: AuthSignUpFormProps) => {
      const { email, password } = data;
      try {
        const resp = await createUserWithEmailAndPassword(auth, email, password);
        onSuccess({ ...data, ...resp });
      } catch (error) {
        onError(error);
      }
    };

  const authResetPassword =
    (onSuccess: AuthFormSubmitSuccessCallback, onError: AuthFormSubmitErrorCallback) =>
    async (data: AuthResetPasswordFormProps) => {
      const { email } = data;
      try {
        await sendPasswordResetEmail(auth, email, { url: `${window.location.origin}/login?email=${email}` });
        onSuccess({ ...data });
      } catch (error) {
        onError(error);
      }
    };

  const authLogout = () => {
    sessionLogout();
    auth.signOut();
  };

  const forceLogout = useCallback(() => {
    sessionLogout();
    auth.signOut();
  }, [sessionLogout]);

  return { authLogin, authSignUp, authResetPassword, authLogout, forceLogout };
};

export default AuthRepository;
export {
  AuthUserNotFoundException,
  type AuthLogInFormProps,
  type AuthSignUpFormProps,
  type AuthResetPasswordFormProps
};
