import { RealWorldExamplesProps } from "@components/orderTest/orderTest3/RealWorldExamples";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { examples } from "@pages/Order/StandardTest/constants";
import { Dispatch, SetStateAction } from "react";

/**
 * @fileoverview This contains the RWEContentCard component and its props type declaration.
 * */

/** Type Declaration for optional parameter to handle toggling of menu in mobile view*/
interface RWEContentCardProps extends RealWorldExamplesProps {
  toggleDrawer?: Dispatch<SetStateAction<boolean>>;
}

/**
 * RWEContentCard component.
 * Fetches and displays real-world examples of the selected test at random.
 * The user can click on the example to fill in the form with the example's data.
 * */
const RWEContentCard = (props: RWEContentCardProps) => {
  const getRandomExampleKeys = () => {
    const keys = Object.keys(examples);
    const randomKeys: string[] = [];

    while (randomKeys.length < 3) {
      const randomIndex = Math.floor(Math.random() * keys.length);
      const randomKey = keys[randomIndex];

      if (!randomKeys.includes(randomKey)) {
        randomKeys.push(randomKey);
      }
    }

    return randomKeys;
  };

  return (
    <Card
      id="test-sample-data"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
      }}
    >
      <CardMedia component="img" height="140" image="/images/trees-docs.png" />
      <CardContent>
        <Typography gutterBottom variant="h5">
          Real-World Examples
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Explore real-life patient scenarios with ease by utilizing our pre-loaded examples.
        </Typography>
      </CardContent>
      <CardActions>
        {getRandomExampleKeys().map((key) => {
          return (
            <Button
              key={key}
              size="small"
              color="primary"
              onClick={() => {
                props.setValue("patient", examples[key]);
                if (props.toggleDrawer) {
                  props.toggleDrawer(false);
                }
              }}
            >
              {examples[key].demography.name}
            </Button>
          );
        })}
      </CardActions>
    </Card>
  );
};

export default RWEContentCard;
