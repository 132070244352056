import "./App.scss";

import ProtectedRoute from "@components/route/Protected";

import LabTest from "@pages/Order/LabTest/LabTest";
import Order from "@pages/Order/Order";
import SourceSelection from "@pages/Order/SourceSelection";
import StandardTest from "@pages/Order/StandardTest";
import TypeSelection from "@pages/Order/TypeSelection";
import { useAuth } from "@store/auth/model";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const LogIn = lazy(() => import("@pages/AuthForm/LogIn/LogIn"));
const ResetPassword = lazy(() => import("@pages/AuthForm/ResetPd/ResetPd"));
const SignUp = lazy(() => import("@pages/AuthForm/SignUp/SignUp"));
const Base = lazy(() => import("../Base/Base"));
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));

const OrderRecord = lazy(() => import("@pages/OrderRecord"));
const Report = lazy(() => import("../Report/Report"));
const QANCCN = lazy(() => import("../QANCCN/QANCCN"));
const Profile = lazy(() => import("../Profile/Profile"));
const Error404 = lazy(() => import("../404/Error404"));
const PatientInsights = lazy(() => import("../PatientInsights/PatientInsights"));
const CaseStudies = lazy(() => import("../CaseStudies/CaseStudies"));
const Docs = lazy(() => import("../Docs/Docs"));
const DocumentationEmbedded = lazy(() => import("../DocumentationEmbedded/DocumentationEmbedded"));
const OrganizationsController = lazy(() => import("@pages/Organizations/OrganizationsController"));
const OrganizationMembers = lazy(() => import("@pages/Organizations/OrganizationMembers"));
const OrganizationInvitation = lazy(() => import("@pages/Organizations/OrganizationInvitation"));

function App() {
  const auth = useAuth();

  return (
    <Routes>
      <Route element={<ProtectedRoute isPermitted={!auth} redirectPath="/" />}>
        <Route path="login" element={<LogIn />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="sign-up" element={<SignUp />} />
      </Route>
      <Route element={<ProtectedRoute isPermitted={!!auth} />}>
        <Route path="/docs" element={<Docs />} />
        <Route path="/" element={<Base />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="order" element={<Order />}>
            <Route index element={<TypeSelection />} />
            <Route path="type-selection" element={<TypeSelection />} />
            <Route path="source-selection" element={<SourceSelection />} />
            <Route path="standard-test" element={<StandardTest />} />
            <Route path="lab-test" element={<LabTest />} />
          </Route>
          <Route path="order-record" element={<OrderRecord />} />
          <Route path="report" element={<Report />} />
          <Route path="qa-nccn" element={<QANCCN />} />
          <Route path="profile" element={<Profile />} />
          <Route path="patient-insights" element={<PatientInsights />} />
          <Route path="case-studies" element={<CaseStudies />} />
          <Route path="organizations" element={<OrganizationsController />}>
            <Route index element={<OrganizationMembers />} />
            <Route path="members" element={<OrganizationMembers />} />
            <Route path="invitation" element={<OrganizationInvitation />} />
          </Route>
          <Route path="documentation" element={<DocumentationEmbedded />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
