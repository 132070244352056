import SentryApp from "@config/sentry";
import AuthProvider from "@store/auth/model";

import { ThemeProvider } from "@store/theme/model";

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

import { createRoot } from "react-dom/client";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import "./custom.scss";
// import { AuthProvider } from "./hooks/auth";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import AlertProvider from "./store/alert/model";
import GuideProvider from "./store/guide/model";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <AlertProvider>
            <GuideProvider>
              <ProSidebarProvider>
                <SentryApp />
              </ProSidebarProvider>
            </GuideProvider>
          </AlertProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
