import { IGuide } from "./type";

/**
 * Initial guide state
 *
 * @type {*}
 * */
const GUIDE_INITIAL_STATE: IGuide = {
  run: false,
  stepIndex: 0
};

export { GUIDE_INITIAL_STATE };
