import { createTheme } from "@mui/material";

const INIT_THEME = createTheme({
  palette: {
    primary: {
      main: "#3a34b2"
    }
  }
});

export { INIT_THEME };
