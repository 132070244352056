import { Autocomplete, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

/**
 * @fileoverview This file contains the FormDropDown component and its prop type declarations.
 *
 * */

/** FormDropDown Props Type Declaration**/
interface FormDropDownProps {
  name: string;
  label: string;
  options: { label: string; value: string }[];
  required?: boolean;
}

/**
 * This is a reusable component for dropdown menus in the form.
 * It has an autocomplete feature that allows the user to search for the options.
 *
 * */
const FormDropDown = (props: FormDropDownProps) => {
  const { control } = useFormContext();
  const { name, label, options, required = false } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          fullWidth
          options={options}
          value={options.find((option) => option.value === value) || null}
          onChange={(_, option) => onChange(option?.value)}
          sx={{ my: 1, width: 250 }}
          renderInput={(params) => <TextField {...params} required={required} label={label} />}
        />
      )}
    />
  );
};

export { type FormDropDownProps };
export default FormDropDown;
