import { GuideCardProps } from "@components/guideCard/GuideCard";

const insertDataManually: GuideCardProps = {
  id: "manual-test-data",
  title: "Enter clinical data manually",
  text: "If you select this option, you will fill out the requisition form and provide clinical information manually.",
  link: "/order/standard-test",
  imageUrl: "/images/illustration-digital-test2.png"
};

const importDataFromEhr: GuideCardProps = {
  title: "Load clinical data from EHR",
  text: "Please reach out to Ataraxis team if you would like us to integrate with your EHR system to automatically pull patient data to requisition form.",
  imageUrl: "/images/system-data.png",
  disable: true,
  buttonText: "Available soon"
};

export { importDataFromEhr, insertDataManually };
