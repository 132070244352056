import { useGuide } from "@/store/guide/model";
import { useAuth } from "@store/auth/model";
import { ACTIONS, EVENTS, STATUS, type CallBackProps } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { updateUserShowGuideStatusOrder } from "./user";

const useGuideRepository = () => {
  const { guideDispatch } = useGuide();
  const navigate = useNavigate();
  const auth = useAuth();

  const handleCallback = async (data: CallBackProps) => {
    const { status, action, index, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      guideDispatch({ run: false, stepIndex: 0 });
      if (!auth) return;
      await updateUserShowGuideStatusOrder(auth.uid, false);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (action === ACTIONS.NEXT && (index === 1 || index === 2)) {
        guideDispatch({
          run: false,
          stepIndex: nextStepIndex
        });
        navigate(`/order-test/${nextStepIndex}`);
      } else if (action === ACTIONS.PREV && (index === 2 || index === 3)) {
        guideDispatch({
          run: false,
          stepIndex: nextStepIndex
        });
        navigate(`/order-test/${nextStepIndex}`);
      }
      guideDispatch({
        run: true,
        stepIndex: nextStepIndex
      });
    }
  };

  return { handleCallback };
};

export default useGuideRepository;
