import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { createContext, useContext, useEffect, useReducer } from "react";
import { AuthAction } from "./type";

const authReducer = (state: User | null, action: AuthAction) => {
  const { type, auth } = action;
  switch (type) {
    case "LOGIN":
      localStorage.setItem("auth", JSON.stringify(auth));
      return auth;

    case "LOGOUT":
      localStorage.removeItem("auth");
      return null;

    default:
      throw new Error("Invalid action type");
  }
};

const AuthContext = createContext<User | null>(JSON.parse(localStorage.getItem("auth") || "null"));

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, authDispatch] = useReducer(authReducer, JSON.parse(localStorage.getItem("auth") || "null"));

  const nextOrObserver = (_auth: User | null) => authDispatch({ type: _auth ? "LOGIN" : "LOGOUT", auth: _auth });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), nextOrObserver);

    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export default AuthProvider;
export { useAuth };
