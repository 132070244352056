import { analytics, db } from "@config/firebase";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  QueryDocumentSnapshot,
  Timestamp,
  type FirestoreDataConverter,
  type SnapshotOptions,
  getDocs,
  Query,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import Base from "./base";
import { IPatient } from "./patient";

const table = "orders";

/**
 * @interface IOrder
 * @field patient: patient for this order
 * @field files_folder_name: folder names for dicom files
 * @field physician: who take the order
 * @field payment_account: account charged for the order
 */
interface IOrder {
  patientId: string;
  physician: string;
  paymentAccount?: string;
  // order_processing?: IOrderProcessing;
  // order_result?: IOrderResult;
  //numberOfMRIFiles: number;
  numberOfHistologyFiles: number;
}

// interface IOrderProcessing {
//   processing_trace_id: number;
//   processing_datetime: Timestamp;
//   status: string;
//   model_id: number;
// }

// interface IOrderResult {
//   result: string;
// }

/**
 * Order of patient prediction
 *
 * @class Order
 * @extends {Base}
 * @implements {IBase}
 */

type Status = "Processed" | "In Progress" | "Failed";

type TestType = "Ataraxis Breast (RUO)" | "Ataraxis Breast (Commercial)";

class Order extends Base implements IOrder {
  patientId: string;

  physician: string;

  status: Status;

  paymentAccount?: string;

  //numberOfMRIFiles: number;

  numberOfHistologyFiles: number;

  testType: TestType;

  ataraxis_score: number;

  constructor(
    uid: string,
    patientId: string,
    physician: string,
    status: Status,
    //numberOfMRIFiles: number,
    numberOfHistologyFiles: number,
    testType: TestType,
    ataraxis_score: number,
    createdTime?: Timestamp,
    paymentAccount?: string,
    id?: string
  ) {
    super(uid, createdTime, id);
    this.patientId = patientId;
    this.physician = physician;
    this.status = status;
    this.paymentAccount = paymentAccount ? paymentAccount : "N/A";
    //this.numberOfMRIFiles = numberOfMRIFiles;
    this.numberOfHistologyFiles = numberOfHistologyFiles;
    this.testType = testType;
    this.ataraxis_score = ataraxis_score;
  }
}

const orderConverter: FirestoreDataConverter<Order> = {
  toFirestore: (order: Order) => {
    return {
      created_time: order.createdTime,
      uid: order.uid,
      patient_id: order.patientId,
      physician: order.physician,
      status: order.status,
      //numberOfMRIFiles: order.numberOfMRIFiles,
      numberOfHistologyFiles: order.numberOfHistologyFiles,
      testType: order.testType,
      payment_account: order.paymentAccount,
      ataraxis_score: order.ataraxis_score
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<Order>, options?: SnapshotOptions) => {
    const data: any = snapshot.data(options);

    return new Order(
      data.uid,
      data.patient_id,
      data.physician,
      data.status,
      //data.numberOfMRIFiles,
      data.numberOfHistologyFiles,
      data.testType,
      data.ataraxis_score,
      data.created_time,
      data.payment_account,
      snapshot.id
    );
  }
};

class OrderRepository {
  /* -------------------------------------------------------------------------- */
  /*                                   Create                                   */
  /* -------------------------------------------------------------------------- */

  /**
   * Add order to firebase document
   *
   * @static
   * @param {Order} order
   * @memberof OrderDB
   */
  static addOrderEntry = async (order: Order): Promise<string> => {
    logEvent(analytics, "add_order_entry");
    const docRef = await addDoc(collection(db, table).withConverter(orderConverter), order);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  };

  /* -------------------------------------------------------------------------- */
  /*                                     Get                                    */
  /* -------------------------------------------------------------------------- */

  /**
   * Get order by id
   *
   * @static
   * @param {string} orderId
   * @memberof OrderDB
   */
  static getOrderById = async (orderId: string): Promise<Order | undefined> => {
    logEvent(analytics, "get_order_by_id");
    const ref = doc(db, table, orderId).withConverter(orderConverter);
    const docSnap = await getDoc(ref);
    return docSnap.data();
  };

  /**
   * Get orders by user uid
   *
   * @static
   * @param {string} uid
   * @memberof OrderDB
   */
  static getOrdersByUId = async (uid: string): Promise<Order[]> => {
    const q: Query<Order> = query(collection(db, table), where("uid", "==", uid)) as Query<Order>;
    const orderSnapshot = await getDocs<Order>(q);
    logEvent(analytics, "get_orders_by_uid", { ordersCount: orderSnapshot.size });
    return orderSnapshot.docs.map((d) => {
      return orderConverter.fromFirestore(d);
    });
  };

  // Helper function that queries Firestore and fetches orders by UIDs
  static getOrdersByUids = async (uids: string[]): Promise<Order[]> => {
    const q: Query<Order> = query(collection(db, table), where("uid", "in", uids)) as Query<Order>;
    const orderSnapshot = await getDocs<Order>(q);
    logEvent(analytics, "get_orders_by_uids", { ordersCount: orderSnapshot.size });
    return orderSnapshot.docs.map((d) => {
      return orderConverter.fromFirestore(d);
    });
  };

  static async getOrderStatus(orderId: string): Promise<string | undefined> {
    if (orderId !== "") {
      logEvent(analytics, "get_specific_order_status");
      const ref = doc(db, table, orderId).withConverter(orderConverter);
      const docSnap = await getDoc(ref);
      return docSnap.data()?.status;
    }
    return undefined;
  }

  static async updateStatus(orderId: string) {
    if (orderId !== "") {
      logEvent(analytics, "update_specific_order_status");
      const ref = doc(db, table, orderId);
      await updateDoc(ref, { status: "Processed " });
    }
  }

  /**
   * Get orders by user uids
   *
   * @static
   * @param {string[]} uids
   * @memberof OrderDB
   */
  // Function that manages Firestore 'in' limit and fetches orders by UIDs
  static batchGetOrdersByUids = async (uids: string[]): Promise<Order[]> => {
    const batchSize = 10; // Firestore 'in' limit
    const batches = [];
    for (let i = 0; i < uids.length; i += batchSize) {
      batches.push(uids.slice(i, i + batchSize));
    }
    const results = await Promise.all(batches.map(OrderRepository.getOrdersByUids));
    return results.flat(); // Flatten the array of orders
  };
}

export { Order, type IPatient, type Status, type TestType };
export default OrderRepository;
