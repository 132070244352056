import { Button, Card, CardActionArea, CardActions, CardMedia, Typography } from "@mui/material";

/**
 * @fileoverview This file contains the GuideCard component and its props type declaration.
 */

/** Type Declaration for GuideCard props**/
interface GuideCardProps {
  title: string;
  text: string;
  imageUrl: string;
  id?: string;
  link?: string;
  disable?: boolean;
  buttonText?: string;
}

/**
 * GuideCard for user to aware the information of the entry in the order test steps 1 and 2.
 *
 * @param {GuideCardProps} props
 * @return {*}
 */
const GuideCard = (props: GuideCardProps) => {
  return (
    <Card
      id={props.id}
      sx={{
        width: { md: "50%", sm: "75%" },
        minWidth: 350,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid",
        borderColor: "grey.500",
        boxShadow: "none",
        color: props.disable ? "grey.500" : undefined
      }}
    >
      <CardMedia component="img" height="240" image={props.imageUrl} />
      <CardActionArea disabled={props.disable} href={props.link ? props.link : ""} sx={{ height: 250, p: 4 }}>
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="body1">{props.text}</Typography>
        {props.buttonText && (
          <CardActions>
            <Button disabled size="small" color="primary">
              {props.buttonText}
            </Button>
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
};

export default GuideCard;
export { type GuideCardProps };
