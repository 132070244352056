import { Button, Container } from "react-bootstrap";

import "./Error404.scss";

const Error404 = () => {
  return (
    <Container className={"text-center error-container"}>
      <img className="logo" src={"/images/Logo.svg"} alt="Ataraxis" />
      <h1>404</h1>
      <p>Page Not Found</p>
      <Button variant="primary" href="/">
        {"Return to home"}
      </Button>
    </Container>
  );
};

export default Error404;
