import FormDropDown from "@components/form/FormDropDown";
import { Box, Typography } from "@mui/material";
import { formCategoryMSDropDown } from "./constants";

/**
 * @fileoverview This file contains the MenopausalStatus component.
 */

/**
 * Menopausal Status component.
 * Order Test Subcomponent that handles the menopausal status of the patient.
 * */
const MenopausalStatus = () => {
  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Menopausal Status
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
        <FormDropDown {...formCategoryMSDropDown} />
      </Box>
    </>
  );
};
export default MenopausalStatus;
