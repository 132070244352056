import { Box } from "@mui/material";

const WithOrderSelectionLayOut = (WrappedComponent: React.FC) => {
  const WithOrderSelectionLayOutComponent = ({ ...props }) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          alignItems: "center",
          gap: 3,
          px: 6,
          pt: 8
        }}
      >
        <WrappedComponent {...props} />
      </Box>
    );
  };
  return WithOrderSelectionLayOutComponent;
};

export default WithOrderSelectionLayOut;
