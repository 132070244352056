import WithGuide from "@hoc/withGuide";
import { Outlet } from "react-router-dom";
import useOrderTest from "./useOrder";

const OrderTest = () => {
  useOrderTest();
  return <Outlet />;
};

export default WithGuide(OrderTest);
