import GuideCard from "@components/guideCard/GuideCard";
import WithTestSelectionLayOut from "@hoc/withOrderSelectionLayOut";
import { importDataFromEhr, insertDataManually } from "./constants";

const SourceSelection = () => {
  return (
    <>
      <GuideCard {...insertDataManually} />
      <GuideCard {...importDataFromEhr} />
    </>
  );
};

export default WithTestSelectionLayOut(SourceSelection);
