import SnackBarAlert from "@components/SnackBarAlert/SnackBarAlert";
import { Button, Container, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

interface IStep {
  label: string;
  description: string;
  guidelines: string[];
  afterwords?: string;
}

const LAB_SUBMIT_MSG = "Laboratory Test Submitted";

const steps: IStep[] = [
  {
    label: "Prepare samples",
    description:
      "The 14-day turnaround time begins once we receive the H&E stained slide from a breast biopsy or excision.",
    guidelines: [
      "Choose a representative slide, i.e., the slide that displays both the greatest amount or highest-grade invasive carcinoma, and, when possible, the least amount of normal or hyperplastic epithelium.",
      "Ensure that the slide is well-prepared, the tissue is thoroughly stained, the coverslip is secure, and the slide is free of any cracks or chips."
    ]
  },
  {
    label: "Ship samples",
    description: "Ship samples in the provided slide box, adhering to these guidelines:",
    guidelines: [
      "Place the slide in the slide box and seal the box with the provided tape.",
      "Place the slide box in the provided specimen bag and seal the bag.",
      "Place the specimen bag in the provided shipping box and seal the box.",
      "Place the shipping box in the provided FedEx Clinical Pak and seal the Pak.",
      "Complete the FedEx airbill and affix it to the FedEx Clinical Pak.",
      "Ship the FedEx Clinical Pak to Ataraxis, 169 Madison Ave #2274, New York, NY 10016."
    ],
    afterwords:
      "Please ensure that the slide container is labeled with 2 unique patient identifiers, such as the patient’s name and date of birth, and the collection date."
  },
  {
    label: "Access test results",
    description: `
      Most results are available within 48 hours of receiving the H&E slide and are accessible through the Ataraxis website.
      `,
    guidelines: [
      "Orders can be submitted online—virtually eliminating the need for follow-up calls to complete any missing information",
      "All patient orders and results reports can be viewed, tracked, and sorted quickly",
      "Orders and results reports may be viewed online, printed, or downloaded as PDFs",
      "You can also receive results via fax or mail."
    ]
  }
];

const LabTestInstruction = () => {
  // Snackbar (toast) for success
  const [snackbarOpen, setSnackbarOpen] = useState(true);

  return (
    <Container maxWidth="lg">
      <Stepper orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label} active expanded>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Typography>
                {step.guidelines.map((guideline, index) => (
                  <li key={`${index}-${guideline}`}>{guideline}</li>
                ))}
              </Typography>
              {step.afterwords && <Typography>{step.afterwords}</Typography>}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Button variant="outlined" component={Link} to="/order-history" size="small">
        Go to Order History
      </Button>
      <SnackBarAlert
        severity="success"
        open={snackbarOpen}
        message={LAB_SUBMIT_MSG}
        onClose={() => setSnackbarOpen(false)}
      />
    </Container>
  );
};

export default LabTestInstruction;
