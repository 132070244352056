import FormDropDown from "@components/form/FormDropDown";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography
} from "@mui/material";
import { formCategoryNDropDown, formCategoryTDropDown } from "./constants";
import { StandardTestForm } from "@utils/interface/order";
import { useState, useEffect } from "react";
import { Controller, useForm, useFormContext, UseFormReturn, type UseFieldArrayRemove } from "react-hook-form";

/**
 * @fileoverview This file contains the ClinicalStaging component.
 */

/**
 * Clinical Staging component.
 * Order Test Subcomponent that handles the clinical staging of the patient.
 * */
const ClinicalStaging = () => {
  const { control, setValue } = useFormContext<StandardTestForm>();
  const [ifUseTStage, setifUseTStage] = useState(false);
  const [ifUseNStage, setifUseNStage] = useState(false);
  const [errorMessageT, setErrorMessageT] = useState("");
  const [errorMessageN, setErrorMessageN] = useState("");

  useEffect(() => {
    if (ifUseTStage) {
      setValue("patient.foci.focusSize", null);
    }
  }, [ifUseTStage, setValue]);

  useEffect(() => {
    if (ifUseNStage) {
      setValue("patient.foci.positiveNodesNum", null);
    }
  }, [ifUseNStage, setValue]);

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Clinical Staging
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: 2, mb: 4 }}>
        <Grid item>
          <Controller
            name={`patient.foci.focusSize`}
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value, ...rest } }) => (
              <TextField
                {...rest}
                label="Tumor size"
                value={value !== undefined ? value : ""}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 1000,
                    disabled: ifUseTStage
                  },
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>
                }}
                error={!!errorMessageT}
                helperText={errorMessageT}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === "") {
                    onChange(undefined);
                    setErrorMessageT("");
                  } else {
                    const numValue = Number(inputValue);
                    if (!isNaN(numValue) && numValue >= 1 && numValue <= 1000) {
                      onChange(numValue);
                      setErrorMessageT("");
                      if (numValue) setifUseTStage(false);
                    } else if (numValue > 1000 || numValue < 1) {
                      setErrorMessageT("Please enter a value between 1 - 1000");
                    }
                  }
                  // const value = parseInt(e.target.value);
                }}
                sx={{ width: 200, ml: 1, mb: -2, mr: 0.5 }}
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ifUseTStage}
                onChange={(e) => {
                  setifUseTStage(e.target.checked);
                  if (e.target.checked) {
                    console.log(1);
                    setValue("patient.foci.focusSize", null);
                  } else {
                    console.log(0);
                    setValue("patient.staging.tnmCategoryT", null);
                  }
                }}
              />
            }
            label="I only know patient's T stage"
          />
        </Grid>
        {ifUseTStage && <FormDropDown {...formCategoryTDropDown} />}
      </Box>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
        <Grid item>
          <Controller
            name={`patient.foci.positiveNodesNum`}
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value, ...rest } }) => (
              <TextField
                {...rest}
                label="Number of positive lymph nodes"
                value={value !== undefined ? value : ""}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 99,
                    disabled: ifUseNStage
                  },
                  endAdornment: <InputAdornment position="end"></InputAdornment>
                }}
                error={!!errorMessageN}
                helperText={errorMessageN}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === "") {
                    onChange(undefined);
                    setErrorMessageN("");
                  } else {
                    const numValue = Number(inputValue);
                    if (!isNaN(numValue) && numValue >= 0 && numValue <= 99) {
                      onChange(numValue);
                      setErrorMessageN("");
                      if (numValue) setifUseTStage(false);
                    } else if (numValue > 99 || numValue < 0) {
                      setErrorMessageN("Please enter a value between 0 - 99");
                    }
                  }
                  // const value = parseInt(e.target.value);
                }}
                sx={{ width: 200, ml: 1, mb: -2, mr: 0.5 }}
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ifUseNStage}
                onChange={(e) => {
                  setifUseNStage(e.target.checked);
                  if (e.target.checked) {
                    console.log(1);
                    setValue("patient.foci.positiveNodesNum", null);
                  } else {
                    console.log(2);
                    setValue("patient.staging.tnmCategoryN", null);
                  }
                }}
              />
            }
            label="I only know patient's N stage"
          />
        </Grid>
        {ifUseNStage && <FormDropDown {...formCategoryNDropDown} />}
      </Box>
    </>
  );
};
export default ClinicalStaging;
