import RWEContentCard from "@components/orderTest/orderTest3/RWEContentCard";
import { TravelExplore } from "@mui/icons-material";
import { Button, Drawer, Theme, useMediaQuery } from "@mui/material";
import { StandardTestForm } from "@utils/interface/order";
import { useState } from "react";
import { UseFormSetValue } from "react-hook-form";

/**
 * @fileoverview This file contains the RealWorldExamples component and its props type declaration.
 * */

/**
 * Type Declaration for RealWorldExamples Props
 * This function is used to set the values in the form according to the chosen real world example.
 * */
export interface RealWorldExamplesProps {
  setValue: UseFormSetValue<StandardTestForm>;
}

/**
 * RealWorldExamples component.
 * Wrapper that handles the responsiveness of the RealWorldExample Component.
 * In larger screens, the menu is displayed next to the form.
 * In smaller screens this menu is wrapped into a drawer that is toggleable by a small button in the corner of the screen.
 * */
const RealWorldExamples = (props: RealWorldExamplesProps) => {
  // Variables for responsiveness
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setIsDrawerOpen(open);
  };
  if (isLargeScreen) {
    return <RWEContentCard setValue={props.setValue} />;
  } else
    return (
      <>
        <Button
          color="primary"
          sx={{
            position: "absolute",
            top: isMediumScreen ? "64px" : "56px",
            right: "0px",
            minWidth: "50px",
            backgroundColor: "primary.light",
            borderRadius: "0px 0px 0px 15px",
            color: "orange",
            "&:hover": {
              backgroundColor: "primary.dark"
            }
          }}
          onClick={toggleDrawer(true)}
        >
          <TravelExplore />
        </Button>
        <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <RWEContentCard setValue={props.setValue} toggleDrawer={setIsDrawerOpen} />
        </Drawer>
      </>
    );
};
export default RealWorldExamples;
