import { Alert, AlertColor, Slide, SlideProps, Snackbar } from "@mui/material";

const TransitionDown = (props: SlideProps) => {
  return <Slide {...props} direction="down"></Slide>;
};

interface SnackBarAlertProps {
  open: boolean;
  message: string;
  severity?: AlertColor;
  onClose?: () => void;
}

const SnackBarAlert = (props: SnackBarAlertProps) => {
  const { open, severity = "error", message, onClose } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      TransitionComponent={TransitionDown}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export { type SnackBarAlertProps, TransitionDown };
export default SnackBarAlert;
